import React from "react";
import { Link } from "gatsby";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header__wrap">
        <header className="header">
          <Link className="header__logo" to="/">
            <img
              style={{ padding: "1rem 0" }}
              src="/images/logo.svg"
              alt="Richard Latimer - Remedial and Sports Massage Therapist"
              width="350"
            />
          </Link>
          <nav className="header__navigation">
            <span className="u-off-screen">
              <a href="#main-content">Jump to main content</a>
            </span>
            <ul className="header__navigation-list">
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/vouchers">Vouchers</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              {this.props.pageContext.url !== "/book/" && (
                <li className="book">
                  <Link to="/book" className="button--primary">
                    Book now
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </header>
      </div>
    );
  }
}
