import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import Header from "./Header";
import Footer from "./Footer";

import "../sass/main.scss";

export default class Body extends React.Component {
  render() {
    const pageTitle =
      _.get(this.props, "pageContext.frontmatter.title") +
      " | Richard Latimer - Sports and Remedial Massage Therapy";
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no"
          />
          <link
            href="https://ik.imagekit.io"
            rel="preconnect"
            crossorigin
          ></link>
          <meta name="google" content="notranslate" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/images/apple-touch-icon.png"
          />

          <meta property="og:title" content={pageTitle} />
          <meta
            property="og:site_name"
            content="Richard Latimer - Remedial and Sports Massage Therapist"
          />
          <meta property="og:url" content="https://www.richardlatimer.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://ik.imagekit.io/oi85dhs4k/tr:w-515/images/3.jpg"
          />

          <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
        </Helmet>
        <div className="wrapper">
          <Header {...this.props} />
          <main id="main-content">{this.props.children}</main>
          <Footer {...this.props} />
        </div>
      </>
    );
  }
}
