import React from "react";
import _ from "lodash";
import moment from "moment-strftime";
import safePrefix from "../utils/safePrefix";

export default class PostPromo extends React.Component {
  render() {
    let display_posts = _.get(this.props, "pageContext.pages")
      .filter(page => page.relativeDir === "posts")
      .slice(0, _.get(this.props, "section.num_posts_displayed"));
    return (
      <section id={_.get(this.props, "section.section_id")} className="posts">
        <div className="row">
          {_.map(
            _.orderBy(display_posts, "frontmatter.date", "desc"),
            (post, post_idx) => (
              <div key={post_idx} className="col-6">
                <div className="p-card postpromo">
                  {/* {_.get(post, "frontmatter.thumb_img_path") && (
                    <img
                      className="thumbnail"
                      src={safePrefix(
                        _.get(post, "frontmatter.thumb_img_path")
                      )}
                      alt={_.get(post, "frontmatter.title")}
                    />
                  )} */}
                  <div className="copy">
                    <a href={safePrefix(_.get(post, "url"))}>
                      <h3 className="p-heading--four postpromo__heading">
                        {_.get(post, "frontmatter.title")}
                      </h3>
                    </a>
                    <h6>
                      Published on{" "}
                      {moment(_.get(post, "frontmatter.date")).strftime(
                        "%B %d, %Y"
                      )}
                    </h6>
                    <p className="summary">
                      {_.get(post, "frontmatter.excerpt")}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    );
  }
}
