import React from "react";
import _ from "lodash";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer__wrap">
        <footer className="footer">
          <div className="footer__copy">
            <div itemType="http://schema.org/PostalAddress" itemProp="address">
              <div className="footer__content">
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.content"
                )}
              </div>
            </div>
            <br />
            <a
              href="https://goo.gl/maps/9NAFTDoQSozSTaCq8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="150"
                src="https://ik.imagekit.io/oi85dhs4k/tr:w-150/images/google-maps.svg"
                alt="Find us on Google Maps"
                loading="lazy"
              />
            </a>
          </div>
          <div className="footer__social">
            <ul className="p-inline-list u-align-text--right">
              {_.map(
                _.get(this.props, "pageContext.site.data.social.links"),
                (link_item, link_item_idx) => (
                  <li key={link_item_idx} className="p-inline-list__item">
                    <a
                      className="subtle-link"
                      href={_.get(link_item, "url")}
                      {...(_.get(link_item, "new_window")
                        ? { target: "_blank" }
                        : null)}
                    >
                      <img
                        src={
                          "https://ik.imagekit.io/oi85dhs4k/tr:w-25" +
                          _.get(link_item, "icon")
                        }
                        alt={_.get(link_item, "text")}
                        width="25"
                        loading="lazy"
                      />
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}
